<template>
    <div id="app">
        <!-- 顶部展位文案 -->
        <!-- 内容部分 -->
        <div class="gl_main_view">
            <div class="gl_button" @click="onClickUser('add')">添加等级</div>
            <el-table :data="tableData" style="width: 100%" :cell-style="{ 'text-align': 'center' }"
                :header-cell-style="{ background: '#B5DFFF', color: '#333333', 'text-align': 'center' }">
                <el-table-column prop="id" label="ID" width="80">
                </el-table-column>
                <el-table-column label="等级图标" width="100">
                    <template slot-scope="scope">
                        <el-image style="width: 50px; height: 50px;border-radius: 50%;" :src="scope.row.logo"
                            fit="scale-down"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="等级名称">
                </el-table-column>
                <el-table-column label="会员数">
                    <template slot-scope="scope">
                        <div>{{ scope.row.number || '0' }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="等级说明">
                    <!--  -->
                    <template slot-scope="scope">
                        <div>{{ scope.row.remark || '-' }}</div>

                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button @click="onClickUser('coyp', scope.row)" type="text">复制</el-button>
                        <el-button @click="onClickUser('edit', scope.row)" type="text">编辑</el-button>
                        <el-button @click="handleDelete(scope.row)" type="text">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <memberListEdit @submitForm="submitForm" @handleClose="handleClose" :allocationList="allocationList"
            :dialogData="dialogData" :dialogValue="dialogValue" :dialogVisible="dialogVisible"></memberListEdit>
    </div>
</template>

<script>
import memberListEdit from "@/views/vipManagement/memberListEdit.vue"

export default {
    // 添加/修改的
    components: {
        memberListEdit,
    },
    data() {
        return {
            tableData: [],
            // 添加/修改/复制 -三个对象
            dialogVisible: false,
            dialogValue: "add", //add-添加 coyp-复制  edit-修改
            dialogData: {},


            // ---
            allocationList: [],//权益配置list
        }
    },
    created() {
        // 获取会员列表
        this.getLevelListAsync()
    },
    mouted() {

    },
    methods: {
        // 点击 添加/修改
        onClickUser(value, data = {}) {
            //获取 权益配置 list 
            this.getAllocationList(data)
            this.dialogVisible = true;
            this.dialogValue = value;
            let msg = JSON.parse(JSON.stringify(data)) //拷贝
            this.dialogData = msg;
        },
        // 关闭弹窗 添加/修改
        handleClose(msg) {
            this.dialogVisible = msg;
        },
        // 添加 / 修改 / 复制 成功
        submitForm() {
            this.dialogVisible = false;
            this.getLevelListAsync();
        },
        // 删除
        handleDelete(data) {
            console.log(data.id)
            this.$confirm('此操作将永久删除该会员等级, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                this.$message.success('删除成功');
            }).catch(() => { });

        },
        // 权益配置 /添加
        async getAllocationList(data) {
            let msg = {};
            if (data.id) {
                msg = { levelId: data.id }
            }
            const { data: res } = await this.$http({
                url: "/member/getFuncList",
                method: "post",
                data: msg
            });
            this.allocationList = res.content
        },
        // 获取列表
        async getLevelListAsync() {
            const { data: res } = await this.$http({
                url: "/member/getLevelList",
                method: "post",
                data: {}
            });
            this.tableData = res.content;
            console.log(this.tableData)
        },

    },
}
</script>
<style scoped>
.gl_main_view {
    width: 100%;
    background: rgba(255, 255, 255);
    opacity: 1;
    border-radius: 10px;
    /* margin-top: 20px; */
    box-sizing: border-box;
    padding: 20px;
}

.gl_button {
    width: 100px;
    height: 34px;
    background: rgba(0, 131, 227);
    opacity: 1;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 22px;
    color: #FFFFFF;
    margin-bottom: 20px;
    cursor: pointer;
}
</style>