<template>
    <div class="gl_vip_wrapper">
        <el-dialog :title="dialogValue == 'edit' ? '修改会员' : '添加会员'" :visible.sync="dialogVisible" width="40%"
            :before-close="handleClose">
            <div class="gl_wrapper">
                <div class="gl_title">基本信息</div>
                <el-form ref="dialogData" :model="dialogData" :rules="rules" label-width="80px">
                    <el-form-item label="等级名称" prop="name">
                        <el-input placeholder="请输入等级名称" v-model="dialogData.name"></el-input>
                    </el-form-item>

                    <el-form-item label="等级序号" prop="sortId">
                        <el-input placeholder="请输入等级序号" type="number" style="width: 50%;" v-model="dialogData.sortId"></el-input>
                    </el-form-item>

                    <el-form-item label="等级背景">
                        <el-upload :disabled="dialogData.background ? true : false" class="avatar-uploader"
                            action="https://apisgu.chinaucv.com/staffPlatform/upload/uploadImage" :show-file-list="false"
                            :on-success="handleAvatarSuccessBg" :before-upload="beforeAvatarUpload" :headers="uploadHeaders"
                            accept="image/jpg,image/jpeg,image/png" name="image" :data="{ type: 0, vehicleModelId: 0 }"
                            :multiple="false">
                            <div style="background: #fff;">
                                <el-image class="avatar" :zIndex="9999" v-if="dialogData.background"
                                    :src="dialogData.background" :preview-src-list="[dialogData.background]" fit="contain">
                                </el-image>
                            </div>
                            <i v-if="!dialogData.background" class="el-icon-plus avatar-uploader-icon"></i>
                            <div class="avatar_delete" v-if="dialogData.background" @click.stop="onClickDeleteImage()">
                                <img style="width:14px;height: 14px;" src="../../assets/error.png" alt="">
                            </div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="等级图标" prop="logo">
                        <el-upload :disabled="dialogData.logo ? true : false" class="avatar-uploader"
                            action="https://apisgu.chinaucv.com/staffPlatform/upload/uploadImage" :show-file-list="false"
                            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :headers="uploadHeaders"
                            accept="image/jpg,image/jpeg,image/png" name="image" :data="{ type: 0, vehicleModelId: 0 }"
                            :multiple="false">
                            <div style="background: #fff;">
                                <el-image class="avatar" :zIndex="9999" v-if="dialogData.logo" :src="dialogData.logo"
                                    :preview-src-list="[dialogData.logo]" fit="contain">
                                </el-image>
                            </div>
                            <i v-if="!dialogData.logo" class="el-icon-plus avatar-uploader-icon"></i>
                            <div class="avatar_delete" v-if="dialogData.logo" @click.stop="onClickDeleteImage()">
                                <img style="width:14px;height: 14px;" src="../../assets/error.png" alt="">
                            </div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="等级说明">
                        <el-input placeholder="请输入等级说明" type="textarea" v-model="dialogData.remark"></el-input>
                    </el-form-item>
                </el-form>
                <div class="gl_title">权益配置</div>
                <div class="gl_info">
                    <div class="gl_flex" v-for="(item, index) in allocationList">
                        <el-checkbox v-model="item.isSelect">{{ item.name }}的会员折扣</el-checkbox>
                        <el-input v-if="item.isSelect" class="gl_input" size="mini" placeholder="请输入内容"
                            v-model="item.discount" @input="formatNum(item.discount, index)">
                            <template slot="append">折</template>
                        </el-input>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="submitForm('dialogData')">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: ["dialogVisible", "dialogData", "dialogValue", "allocationList"],
    data() {
        return {
            rules: {
                name: [
                    { required: true, message: '请输入等级名称', trigger: 'blur' },
                    { min: 1, max: 20, message: '长度在 20 个字符以内', trigger: 'blur' }
                ],
                sortId: [
                    { required: true, message: '请输入等级序号', trigger: 'blur' }
                ],
                logo: [
                    { required: true, message: '请上传等级图标', trigger: 'blur' }
                ],
            },
            uploadHeaders: {},
            loading: null,
        }
    },
    created() {
        // 获取token
        let token = sessionStorage.getItem("token");
        this.uploadHeaders = { token };
    },
    mouted() {

    },
    methods: {
        // 上传照片 - logo
        handleAvatarSuccess(file) {
            this.loading.close();
            this.$set(this.dialogData, 'logo', file.content.urls[0]);
        },
        // 上传照片 - background
        handleAvatarSuccessBg(file) {
            this.loading.close();
            this.$set(this.dialogData, 'background', file.content.urls[0]);
        },
        // 上传图片
        beforeAvatarUpload() {
            this.loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        handleClose() {
            this.$emit('handleClose', false);
        },
        //底部提交
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let funcList = [];
                    for (var a = 0; a < this.allocationList.length; a++) {
                        let item = this.allocationList[a];
                        console.log(item)
                        if (item.isSelect) {
                            if (item.discount == '' || item.discount == null) {
                                return this.$message.error(`权益配置：${item.name}字段 未填写折扣哦～`);
                            }
                            if (item.discount < 1 || item.discount > 10) {
                                return this.$message.error(`权益配置：${item.name}字段 折扣有误哦～`);
                            }
                            funcList.push({ code: item.code, discount: item.discount })
                        }
                    }
                    // 请求接口
                    this.dialogData.funcList = funcList
                    if (this.dialogValue == 'copy') {
                        delete this.dialogData.id
                    }
                    //编辑替换下id
                    if (this.dialogValue == 'edit') {
                        this.dialogData.levelId = this.dialogData.id
                    }
                    this.beforeAvatarUpload(); //加载
                    const { data: res } = await this.$http({
                        url: "/member/saveLevel",
                        method: "post",
                        data: this.dialogData
                    });
                    this.loading.close();
                    if (res.resultStates != 0) return this.$message.error(res.message);
                    this.$emit('submitForm', true); //添加成功的回调
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //验证两位小数
        formatNum(val, key) {
            let temp = val.toString();
            temp = temp.replace(/。/g, ".");
            temp = temp.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
            temp = temp.replace(/^\./g, ""); //验证第一个字符是数字
            temp = temp.replace(/\.{2,}/g, ""); //只保留第一个, 清除多余的
            temp = temp.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            temp = temp.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
            this.allocationList[key].discount = temp;
        },
    },
}
</script>
<style scoped >
.gl_wrapper {
    width: 100%;
    height: 460px;
    overflow: scroll;
}

.gl_title {
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-bottom: 12px;
}

.avatar-uploader {
    width: 86px;
    height: 92px;
    position: relative;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 86px;
    height: 92px;
    line-height: 92px;
    text-align: center;
    border: 1px solid #CCCCCC;
    overflow: hidden;
    border-radius: 4px;
}

.avatar {
    width: 86px;
    height: 92px;
    display: block;
    border-radius: 4px;
}

.avatar_delete {
    position: absolute;
    top: -10px;
    right: 0;
}

.gl_info {
    width: 100%;
    border: 1px solid #CECECE;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 6px 40px 16px 50px;
}

.gl_input {
    width: 150px;
}

.gl_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
    margin-top: 16px;
}
</style>

<style>
.gl_vip_wrapper .el-dialog {
    margin-top: 30px !important;
}
</style>